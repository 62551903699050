import aboutImage from "../../assets/images/home/home-about-us.png";
const servicesList = ["בניית אתרים: אתרי תדמית, חנויות מקוונות, פורטלים ועוד", "פיתוח מערכות אינטרנטיות: מערכות CRM, מערכות ניהול מלאי, מערכות ERP ועוד.", "פיתוח אפליקציות: אפליקציות עסקיות, משחקים, אפליקציות שירות ועוד."];

const whyUsList = ["ניסיון ומומחיות: צוות מנוסה ומקצועי בעל 4 שנות ניסיון בתעשייה.", "שירות אישי: אנחנו זמינים עבורכם לכל שאלה ותמיכה לאורך כל התהליך.", "מחירים הוגנים: אנחנו מציעים פתרונות איכותיים במחירים נוחים לכל כיס.", "מחויבות לתוצאות: אנחנו עובדים קשה כדי להבטיח שתקבלו את הפתרון המושלם עבורכם."];

const HomeAboutComponent = () => {
  return (
    <div className="home-about-container">
      <div className="about-image-container">
        <img src={aboutImage} alt="about us creative" />
      </div>
      <div className="about-content-container">
        <h2>הסיפור שלנו</h2>
        <p>
          אנחנו ב CODE IL מאמינים שכל עסק, קטן כגדול, צריך נוכחות דיגיטלית חזקה ומתקדמת.
          <br />
          עם ניסיון של 4 שנים בפיתוח מערכות אינטרנטיות מורכבות, החלטנו לצאת לדרך עצמאית מתוך שליחות אמיתית.
          <br /> המטרה שלנו היא לאפשר לכל אחד, בין אם מדובר ביזם מתחיל או חברה גדולה, להקים אתר אינטרנט או אפליקציה איכותיים, בעלי חווית משתמש מקסימלית ובמחיר הוגן.
        </p>

        <div className="specialty-list-container">
          <h3>למה לבחור בנו? </h3>
          {whyUsList.map((specialty, idx) => (
            <div key={idx} className="specialty-container">
              <div className="checked-container">
                <i className="fa-solid fa-check"></i>
              </div>
              <p className="specialty-content">{specialty}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeAboutComponent;
