import livingMemories from "../../assets/images/home/clients projects/livingMemories.png";
import revitalTamir from "../../assets/images/home/clients projects/revitalTamir.png";
import project2 from "../../assets/images/home/clients projects/BG2.png";

const projects = [
  { img: livingMemories, title: "זיכרונות חיים", url: "https://izcor.org/" },
  { img: revitalTamir, title: "רביטל תמיר - לעצב את חייך", url: "https://www.revital-tamir.com/" },
  // { img: project2, title: "Web App Development", url: "" },
];

const HomeClientsProjectsComponent = () => {
  const clientProjectCliked = (url) => {
    window.open(url);
  };

  return (
    <div className="home-clients-projects-container">
      <div className="clients-header-container">
        <div className="title-container">
          <h1>הפרויקטים שלנו: הצצה לעבודות שבנינו עבור לקוחותינו</h1>
          {/* <button className="main-button">הצג הכל</button> */}
        </div>
        <p>הצטרפו אלינו למסע קצר ותתרשמו ממגוון רחב של אתרים ואפליקציות שפיתחנו עבור לקוחותינו המרוצים.</p>
      </div>

      <div className="clients-projects-list-container">
        {projects.map((project, idx) => (
          <div key={idx} className="client-project-card-container" style={{ backgroundImage: `url(${project.img})` }}>
            <div className="card-content-container">
              <h3>{project.title}</h3>
              <button
                onClick={() => {
                  clientProjectCliked(project.url);
                }}
              >
                <i className="fa-solid fa-arrow-up"></i>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

/*  */

export default HomeClientsProjectsComponent;
