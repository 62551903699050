import React, { useState } from "react";

import logo from "../assets/images/Logo.png";

const HeaderComponent = ({ isMobileScreen, isMenuOpen, toggleOpenNavMenu }) => {
  const closeNavMenu = () => {
    toggleOpenNavMenu(false);
  };
  return (
    <header className="header-container">
      <div className="logo-container">
        <img src={logo} alt="logo" />
      </div>

      <nav className={`nav-menu-container ${isMobileScreen && !isMenuOpen ? "menu-close" : "menu-open"}`}>
        <button className="close-menu-button" onClick={closeNavMenu}>
          <i className="fa-solid fa-xmark"></i>
        </button>
        <div className="nav-links-container">
          <a href="">בית</a>
          <a href="">הסיפור שלנו</a>
          {/* <a href="">Blog</a> */}

          {isMobileScreen && (
            <div className="language-container">
              <button className="main-button">צרו קשר</button>
            </div>
          )}
        </div>
      </nav>

      {!isMobileScreen && (
        <div className="language-container">
          <button className="main-button">צרו קשר</button>
        </div>
      )}

      {isMobileScreen && (
        <button
          className="mobile-menu-button"
          onClick={() => {
            toggleOpenNavMenu(!isMenuOpen);
          }}
        >
          <i className="fa-solid fa-bars"></i>
        </button>
      )}
    </header>
  );
};

export default HeaderComponent;
