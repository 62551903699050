import logo from "../../assets/images/white logo.png";
const HomeBannerComponent = () => {
  return (
    <div className="home-banner-container">
      <div className="banner-content-container">
        <div className="main-content">
          <h1>הגשימו את החזון הדיגיטלי שלכם עם</h1>
          <div className="logo-container">
            <img src={logo} />
          </div>
        </div>
        <h2>אנחנו כאן בשביל לבנות לכם אתר אינטרנט או אפליקציה שתהפוך את החלומות שלכם למציאות</h2>
        <button className="main-button">צרו קשר לקבלת ייעוץ חינם</button>
      </div>
    </div>
  );
};

export default HomeBannerComponent;
