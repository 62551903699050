import HomeAboutComponent from "../components/homePage/HomeAboutComponent";
import HomeBannerComponent from "../components/homePage/HomeBannerComponent";
import HomeClientsProjectsComponent from "../components/homePage/HomeClientsProjectsComponent";
import HomeContactUsComponent from "../components/homePage/HomeContactUsComponent";
import HomeServicesComponent from "../components/homePage/HomeServicesComponent";

export const HomePage = () => {
  return (
    <div>
      <HomeBannerComponent />
      <HomeAboutComponent />
      <HomeServicesComponent />
      <HomeClientsProjectsComponent />
      <HomeContactUsComponent />
    </div>
  );
};

export default HomePage;
