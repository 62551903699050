import element1 from "../../assets/images/home/services design elements/Rectangle 24.png";
import element2 from "../../assets/images/home/services design elements/Rectangle 25.png";
import element3 from "../../assets/images/home/services design elements/Group 70.png";
import element4 from "../../assets/images/home/services design elements/Frame 76.png";

import service1 from "../../assets/images/home/services design elements/service1.png";
import service2 from "../../assets/images/home/services design elements/service2.png";
import service3 from "../../assets/images/home/services design elements/service3.png";
import service4 from "../../assets/images/home/services design elements/service4.png";

const services = [
  { img: service1, title: "בניית אתרים ודפי נחיתה", content: "אנו מציעים מגוון פתרונות בניית אתרים מקצועיים ומותאמים אישית, החל מאתרי תדמית ועד לחנויות מקוונות ופורטלים מורכבים. צוות המומחים שלנו ילווה אתכם לאורך כל התהליך, החל מאפיון צרכים ועד לעיצוב ובניית אתר אינטרנט איכותי, בעל חווית משתמש מעולה ונגישות לכל מכשיר", color: "#FFF2F8" },
  { img: service2, title: "פיתוח מערכות אינטרנטיות", content: "אנו מתמחים בפיתוח מערכות אינטרנטיות מתקדמות ומותאמות אישית, תוך שימוש בטכנולוגיות העדכניות ביותר. בין אם אתם זקוקים למערכת CRM יעילה לניהול קשרי לקוחות, מערכת ניהול מלאי חכמה או מערכת ERP מקיפה לניהול כל תחומי הפעילות של הארגון, צוות המומחים שלנו יבנה עבורכם את המערכת המושלמת שתסייע לכם לייעל את תהליכי העבודה, לשפר את הפרודוקטיביות ולהגדיל את הרווחים.", color: "#DEFFEE" },
  { img: service3, title: "פיתוח אפליקציות", content: "אנו מציעים שירותי פיתוח אפליקציות מקצועיים לכל פלטפורמה, החל מאנדרואיד ו-iOS ועד אפליקציות רב-מערכתיות. צוות המומחים שלנו ילווה אתכם לאורך כל התהליך, החל משלב הרעיון ועד להשקת האפליקציה בחנויות האפליקציות, תוך הקפדה על עיצוב גרפי מושך, חווית משתמש מעולה ותפקודיות גבוהה.", color: "#FFF7E3" },
  { img: service4, title: "שירותי ענן", content: "אנו מציעים מגוון שירותי ענן מתקדמים, החל מאיחסון אתרים אמין וגיבוי נתונים ועד לשרתי ייעודיים המותאמים אישית לצרכים שלכם. פתרונות הענן שלנו יעזרו לכם לשפר את הביצועים, להפחית את העלויות ולהבטיח את הגנת הנתונים שלכם.", color: "#F1F7FF" },
];

const HomeServicesComponent = () => {
  return (
    <div className="home-services-container">
      <img src={element1} alt="" className="element1" />
      <img src={element4} alt="" className="element4" />

      <div className="content-container">
        <img src={element2} alt="" className="element2" />
        <h2>השירותים שלנו</h2>
        <p>
          אנו מציעים מגוון פתרונות בניית אתרים מקצועיים ומותאמים אישית, החל מאתרי תדמית ועד לחנויות מקוונות ופורטלים מורכבים. צוות המומחים שלנו ילווה אתכם לאורך כל התהליך, החל מאפיון צרכים ועד לעיצוב ובניית אתר אינטרנט איכותי, בעל חווית משתמש מעולה ונגישות לכל מכשיר. <br /> בין אם אתם עסק קטן שמתחיל את דרכו או חברה גדולה המעוניינת לשדרג את הנוכחות הדיגיטלית שלה, אנו ב CODE IL נספק לכם את הפתרון המושלם.
        </p>
        <button className="main-button">צרו קשר</button>
      </div>

      <div className="services-list-container">
        <div className="low-services-container">
          {services.map(
            (service, idx) =>
              idx <= 1 && (
                <div key={idx} className="service-card-container">
                  <div className="service-img-container" style={{ backgroundColor: `${service.color}` }}>
                    <img src={service.img} alt={`service${idx + 1}`} />
                  </div>
                  <h1>{service.title}</h1>
                  <p>{service.content}</p>
                </div>
              )
          )}
        </div>
        <div className="high-services-container">
          {services.map(
            (service, idx) =>
              idx >= 2 && (
                <div key={idx} className="service-card-container">
                  <div className="service-img-container" style={{ backgroundColor: `${service.color}` }}>
                    <img src={service.img} alt={`service${idx + 1}`} />
                  </div>
                  <h1>{service.title}</h1>
                  <p>{service.content}</p>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeServicesComponent;
