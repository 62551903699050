import logo from "../assets/images/Logo.png";

const FooterComponent = () => {
  return (
    <footer className="footer-container">

      <div className="footer-content-container">
        
        <div className="company-details-container">
          <div className="logo-container">
            <img src={logo} alt="CodeIL logo" />
          </div>
          <p>פתרונות דיגיטליים יצירתיים ומותאמים אישית</p>
          {/* <div className="social-media-container">
            <button>
              <i className="fa-brands fa-facebook"></i>
            </button>
            <button>
              <i className="fa-brands fa-instagram"></i>
            </button>
            <button>
              <i className="fa-brands fa-linkedin"></i>
            </button>
          </div> */}
        </div>

        <div className="links-container">
          <h2>קישורים מהירים</h2>
          <a href="">בית</a>
          <a href="">הסיפור שלנו</a>
          {/* <a href="">יצירת קשר</a> */}
          {/* <a href="">Blog</a> */}
        </div>

        <div className="contact-container">
          <h2>יצירת קשר</h2>
          <div className="contact-details-container">
            <span>
              <i className="fa-solid fa-envelope"></i>
            </span>
            <p>codeIL@gmail.com</p>
          </div>
          <div className="contact-details-container">
            <span>
              <i className="fa-solid fa-phone"></i>
            </span>
            <p>050-2215233</p>
          </div>
        </div>

      </div>

      <div className="footer-terms-container">
        <span>© 2024 CodeIL. All rights reserved</span>
        <div className="terms-container">
          <button>Privacy Policy</button>
          <span>|</span>
          <button>Terms of Use</button>
        </div>
      </div>

    </footer>
  );
};

export default FooterComponent;
