import contact1 from "../../assets/images/home/contact1.png";
import contact2 from "../../assets/images/home/contact2.png";

const HomeContactUsComponent = () => {
  return (
    <div className="home-contact-us-container">
      <div className="contact-us-container">
        <div className="decor-element-background">
          <img src={contact2} alt="" />
        </div>

        <div className="content-container">
          <h1>צרו איתנו קשר</h1>
          <p>צוות המומחים שלנו ישמח לשמוע על הצרכים שלכם ולעזור לכם לבנות את הפתרון הדיגיטלי המושלם עבור העסק שלכם.</p>
        </div>

        <div className="form-container">
          <div className="input-container">
            <input type="text" placeholder='הקלד כתובת דוא"ל' />
          </div>
          <div className="input-container">
            <input type="text" placeholder="הקלד מספר טלפון" />
          </div>
          <button className="main-button">שלח</button>
        </div>
      </div>
    </div>
  );
};

export default HomeContactUsComponent;
