import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";
import { useEffect, useLayoutEffect, useState } from "react";

function App() {
  const mobileMaxWidth = 768;
  const [windowSize, setWindowSize] = useState();
  const [isMobileScreen, setIsMobileScreen] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (windowSize && windowSize.innerWidth <= mobileMaxWidth) setIsMobileScreen(true);
    else setIsMobileScreen(false);
  }, [windowSize]);

  const toggleOpenNavMenu = (open) => {
    setIsMenuOpen(open);
  };

  return (
    <div className="app-caontainer">
      {isMenuOpen && (
        <div
          className="main-screen"
          onClick={() => {
            toggleOpenNavMenu(!isMenuOpen);
          }}
        ></div>
      )}
      <Router>
        <HeaderComponent isMobileScreen={isMobileScreen} isMenuOpen={isMenuOpen} toggleOpenNavMenu={toggleOpenNavMenu} />
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
        <FooterComponent />
      </Router>
    </div>
  );
}

export default App;
